import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Inner from '../components/Inner'
import InternalLink from '../components/Buttons/InternalLink/InternalLink'
import { BUTTON_TYPES } from '../constants/buttonTypes'
import { Heading2, Typo2 } from '../components/Typography/Typography'
import { HEADER_TYPES } from '../constants/headerTypes'

const NotFoundPage = () => (
  <Layout headerType={HEADER_TYPES.alt} headerDarkLogo>
    <SEO title="404: Not found" />
    <Container
      pt={['10rem', '', '6.25rem']}
      pb={['6.25rem']}
      textAlign="center"
    >
      <Inner>
        <Container mb={['3rem']}>
          <Heading2>Page not found</Heading2>
        </Container>
        <Container mb={['2rem']}>
          <Typo2>Sorry, we can't find the page! </Typo2>
          <Typo2>It might be an old link or maybe it moved.</Typo2>
        </Container>
        <InternalLink to="/" btnType={BUTTON_TYPES.secondary}>
          Back to home page
        </InternalLink>
      </Inner>
    </Container>
  </Layout>
)

export default NotFoundPage
